/**
* Table Global
*/
.table-a-la-orden thead tr th {
    border-right: 1px solid #708595;
}

.table-a-la-orden thead tr th:last-child {
    border-right: none;
}

.table-a-la-orden tbody tr:nth-child(odd) {
    background-color: #d0d9df;
}

.table-a-la-orden tbody tr td {
    text-align: center;
}

/**
* Scroll style
*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #eee;
}

::-webkit-scrollbar-thumb {
    background-color: #ececec;
    border-radius: 10px;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #ececec;
}

* {
    font-family: "Poppins-Regular", sans-serif;
}
